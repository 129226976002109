import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layouts/default'

const PageIntegrations = () => (
	<Layout>

		<div className="container-fluid ">
			<h1 className="text-white text-center mt-5 mb-5" style={{letterSpacing: '0.06em', fontWeight: 200 }}>Integrations with Other Applications</h1>
			<div className="container">

				<div className="row">
					<div className="col-md-3 mb-4  card card-feature" style={{minHeight: '100vh'}}>
						<div className="py-2 sticky-top">
							<h6 class="menu-title">Integrations</h6>
							<div className="nav flex-column">
								<a href="/integrations/#intro" className="nav-link activex">Introduction</a>
								<a href="/integrations/#integrations" className="nav-link">Integrations</a>
							</div>
						</div>
					</div>
					<div className="col-md-9 mb-4">
						<div className="card card-feature">
							<div className="help-body" style={{padding: 40}}>
								<p className="pt-2" style={{fontWeight: 100, fontSize: 18}}>
								Reldesk provides integrations with a number of select applications and services to provide a more streamlined workflow, while keeping things easy to use.
								See below for a list of the integrations we've built and how to connect them up to your account.
								</p>

								<h2 id="integrations">Integrations</h2>
								
								<h4>Productivity</h4>

								<h3 className="mt-4">Google G Suite</h3>
								<p>
									Google's G Suite provides GMail, Sheets and more. Our integration allows you to easily connect your Gmail account to send tracked email and easily import data via Google Sheets.
								</p>

								<h3 className="mt-4">Slack</h3>
								<p>
									Slack is a real-time messaging services for teams. Our integration allows you to receive instant notifications to a Slack channel when a new lead comes in.
								</p>

								<h3 className="mt-4">iCal & Calendars</h3>
								<p>
									iCal is Apple's calendar service. Our integration provides a calendar feed to iCal and other supported Calendars such as Google Calendar.
								</p>
								<ul>
									<li><a href="/integrations/google-calendar/">Google Calendar</a></li>
								</ul>

								<h4>Financial</h4>

								<h3 className="mt-4">Xero</h3>
								{/* <h3 className="mt-4"><a href="/integrations/xero/">Xero</a></h3> */}
								<p>
									Xero is an online accounting system designed for small businesses. Our integration allows you to connect the two so that you can view financial information and client value within your Reldesk contact record.
								</p>

								<h3 className="mt-4">Quickbooks</h3>
								<p>
									Quickbooks is an online accounting system designed for small businesses. Our integration allows you to connect the two so that you can view financial information and client value within your Reldesk contact record.
								</p>


							</div>
						</div>
					</div>
				</div>
		    </div>
		</div>


	</Layout>
)

export default PageIntegrations
